import React, { useEffect, useState } from "react";

import group2 from "../../images/Group 2.png"
import videop from "../../images/videop.png"
import group24 from "../../images/Group 24.png"
import maskGroup6 from "../../images/Mask Group 6.png";
import maskGroup from "../../images/Mask Group.png"
import VideoCard from "../project/VideoCard";
import { Link, useHistory } from "react-router-dom";
import Projects from "../project/Projects";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import { addMergeVideos } from "../../actions/mergeAction";

const DashboardContent = () => {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const merges = useSelector(state => state.merge);
    const brandName = useSelector(state => state.rebrand.data);

    const [projectType, setProjectType] = useState('template');
    const [loadProject, setLoadProject] = useState(false);
    const [mergeData, setMergeData] = useState(merges.data);





    const fetchMerge = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-merge-campaigns`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setMergeData(res.data.data.data);
                dispatch(addMergeVideos(auth.token));
            } else {
                dispatch({ type: 'ADD_CAMPAIGNS', payload: [] });
            }
        }).catch(err => {

        })
    }




    useEffect(() => {
        fetchMerge();
    }, []);

    return (
        <>
            <section className="banner-video-project">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="video-project text-white align-items-center d-flex justify-content-between">
                                <h5 className="m-0">Video <span>Projects</span></h5>
                                <Link to="/projects">View all <span><i className="fas fa-chevron-circle-right"></i></span></Link>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        {
                            mergeData.length > 0 ?

                                mergeData.map((item, index) => {
                                    return (
                                        <VideoCard item={item} fetchCampaigns={fetchMerge} key={item.id} />
                                    )
                                })
                                : <div className="loader-center text-center" style={{ fontSize: "20px", color: "#ffffff" }}>You have not created any project yet. Click "Create New Project" to start creating videos</div>
                        }
                        {
                            loadProject && mergeData.length === 0 ? <div className="loader-center text-center" style={{ fontSize: "20px", color: "#ffffff" }}><i className="fa fa-spinner fa-spin mr-2" /> Fetching Campaigns</div> : ''
                        }
                        {
                            mergeData.length < 1 && mergeData.length > 0 ? <div className="loader-center text-center" style={{ fontSize: "20px", color: "#ffffff" }}>Sorry, you have not created any project with that name</div> : ''
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardContent;
