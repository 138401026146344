import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../actions/authAction";

const ProfilePassword = () => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        password: '',
        new_password: '',
        confirm_password: '',
    });
    const [error, setError] = useState('');

    const validatePassword = () => {
        const { new_password, confirm_password } = userDetails;
        if (new_password.length < 8) {
            return "New password must be at least 8 characters long.";
        }
        if (!/[A-Z]/.test(new_password)) {
            return "New password must contain at least one uppercase letter.";
        }
        if (!/[0-9]/.test(new_password)) {
            return "New password must contain at least one number.";
        }
        if (!/[a-z]/.test(new_password)) {
            return "Password must contain at least one lowercase letter."
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(new_password)) {
            return "Password must contain at least one special character."
        }
        if (new_password !== confirm_password) {
            return "New password and confirm password do not match.";
        }
        return null;
    };
    const onInputChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        const validationError = validatePassword();
        if (validationError) {
            setError(validationError);
            setLoader(false);
            return;
        }
        dispatch(updatePassword(userDetails, setLoader));
    }

    return (
        <>
            <div className="personal-details-box ">
                <div className="row">
                    <div className="col-md-10 mx-auto">

                        <form className="form-box mt-5" method="post" onSubmit={(e) => onFormSubmit(e)}>
                            <h6 className="text-center">Reset Password</h6>
                            {error && <div className="alert alert-danger">{error}</div>}

                            <div className="row mt-5 ">
                                <div className="col-md-12">
                                    <div className="form-group  ">
                                        <small className="form-text text-muted">Current Password</small>
                                        <input type="password" className="form-control"
                                            placeholder="**********" name="password" required
                                            onChange={(e) => onInputChange(e)}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <small className="form-text text-muted">New Password</small>
                                        <input type="password" className="form-control"
                                            placeholder="**********" name="new_password" required
                                            onChange={(e) => onInputChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <small className="form-text text-muted">Confirm Password</small>
                                        <input type="password" className="form-control"
                                            placeholder="**********" name="confirm_password" required
                                            onChange={(e) => onInputChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <button type="submit" className="form-control btn-change6" disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Update
                                    </button>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ProfilePassword;