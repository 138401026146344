import { Helmet } from "react-helmet";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Navbar from "../Navbar";
import React, { useEffect, useState } from "react";
import "./css/editor.css";
import "./css/responsive.css";
import { setAlert } from "../../actions/alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import { baseURL } from "../../global/global";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import HoverVideoPlayer from "react-hover-video-player";
import queryString from "query-string";


const ReelMarge = ({ location }) => {
    const history = useHistory();
    const dispatch = useDispatch()

    const auth = useSelector(state => state.auth);
    const brandName = useSelector(state => state.rebrand.data);

    const [mediaReady, setMediaReady] = useState(false);
    const [loader, setLoader] = useState(false);
    const [play, setPlay] = useState(false);
    const [controlsWidth, setControlsWidth] = useState(100);
    const [ratio, setRatio] = useState('16:9');
    const [editRatio, setEditRatio] = useState('')
    const [videos, setVideos] = useState([]);
    const [mergeVideos, setMergeVideos] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(false);
    const [mergeData, setMergeData] = useState({
        data: [],
        name: '',
        preview_image: false,
        ratio: '16:9',
        id: ''
    });


    const [percent, setPercent] = useState(0);
    const [uploadedVideo, setUploadedVideo] = useState([]);
    const [slideVideo, setSlideVideo] = useState([])
    const [checkVideo, setCheckVideo] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [mediaId, setMediaId] = useState(false);
    const [editId, setEditId] = useState("")


    let dimArr = ratio.split(':');
    let allowedExt = ['video/mp4'];



    /* const handleCrop = (image, index) => {
         console.log(image);
         setCropModel(true);
         setCropImg(image);
         setCropIndex(index);
     }*/

    const openDeletePopUp = (id) => {
        setMediaId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        removeMedia(mediaId)
    }

    const fetchUserLibrary = () => {
        axios({
            method: 'POST',
            url: `${baseURL}user-library`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setVideos(res.data.data.videos);
            }
        }).catch(err => {

        })
    }



    const selectVideo = (image, index) => {

        let jsonData = {
            file_url: `${image.file}`,
            preview_image: `${image.video_screenshot}`,
            index: index,
            type: 'video'
        }
        setVideos(jsonData);

    }

    const removeMedia = (id) => {
        let formData = new FormData();
        formData.append('id', id);

        axios({
            method: 'POST',
            url: `${baseURL}delete-media-file`,
            data: formData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            setShowDelete(false);
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchUserLibrary();
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
        }).catch(err => {
            dispatch(setAlert('Getting server error', 'danger'));
            setShowDelete(false);
        })
    }

    const handleRatio = (e) => {
        setMergeData({ ...mergeData, ratio: e.target.value });
        setRatio(e.target.value);
    }

    const handleMerge = (image, index) => {

        let jsonObj = {
            url: `${image.file}`,
            preview_image: `${image.video_screenshot}`,
            index: index,
            type: 'video'
        }
        setCurrentSlide(jsonObj);
        setMergeVideos([...mergeVideos, jsonObj]);
    }

    const deleteSlide = (index) => {
        let removeArr = [...mergeVideos];
        removeArr.splice(index, 1);

        setMergeVideos(removeArr);
    }


    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            mergeVideos,
            result.source.index,
            result.destination.index
        );

        setMergeVideos(items);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const activeSlide = (video) => {
        setCurrentSlide(video)
    }

    async function videoPlay(video, index) {
        return new Promise((resolve, reject) => {
            if (index === 0) {
                setTimeout(() => {
                    resolve(video);
                }, 0)
            } else {
                setTimeout(() => {
                    resolve(video);
                }, 8000)
            }

        });
    }

    const handlePlay = async () => {
        console.log('click function');
        setPlay(true);

        for (let i = 0; i < mergeVideos.length; i++) {
            await videoPlay(mergeVideos[i], i).then(r => {
                setCurrentSlide(r)
            }).catch(err => {
                console.log(err);
                setCurrentSlide(mergeVideos[i])
            })

        }

        setPlay(false);

    }

    const handlePause = () => {
        setPlay(false);
    }

    const fetchEdit = (id) => {

        axios({
            method: "POST",
            url: `${baseURL}fetch-merge`,
            data: { id: id },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {

                setMergeVideos(JSON.parse(res.data.data[0].data));
                setRatio(res.data.data[0].ratio);
                setMergeData({ ...mergeData, name: res.data.data[0].name, id: res.data.data[0].id });

                let currVideo = JSON.parse(res.data.data[0].data);

                let jsonObj = {
                    url: `${currVideo[0].url}`,
                    preview_image: `${currVideo[0].preview_image}`,
                    index: 0,
                    type: 'video'
                }
                setCurrentSlide(jsonObj);

            } else {

            }
        }).catch(err => {

        })
    }


    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        mergeData.data = mergeVideos;
        mergeData.preview_image = mergeVideos[0].preview_image;

        axios({
            method: 'POST',
            url: `${baseURL}create-merge`,
            data: mergeData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                setLoader(false);
                history.push('/projects');
            } else {
                setLoader(true)
                dispatch(setAlert(res.data.message, 'danger'));
            }
        }).catch(err => {
            setLoader(false);
        })

    }


    /*useEffect(()=>{
        if(videoData.length > 0){
            let filterVideo = videoData.filter((video) => video.status === "6");
            setVideos(filterVideo);
        }
    },[ratio])*/

    const onInputFile = (e) => {

        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 5000000;
                let type = "image";
                if (e.target.files[0].type === "video/mp4") {
                    allowedSize = 20000000;
                    type = "video";
                }

                if (e.target.files[0].size < allowedSize) {
                    setLoader(true);

                    const formData = new FormData();
                    formData.append('file_type', type);
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}upload-file`, formData, options).then(res => {
                        if (res.data.status === "success") {
                            fetchUserLibrary();
                        }
                        setLoader(false);
                        setPercent(0);
                    })

                } else {
                    swal("Oops!", "Max allowed size for video is 20MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    useEffect(() => {
        let preWidth = document.querySelector('.preview-wrapper');
        let parentWidth = preWidth.offsetWidth;

        let wrapWidth = document.querySelector('.aspect-ratio-wrapper');
        let demoWidth = (wrapWidth.offsetWidth / parentWidth * 100).toFixed();

        setControlsWidth(demoWidth);
    }, [])



    useEffect(() => {

        fetchUserLibrary();

        if (queryString.parse(location.search).project_id !== undefined) {
            fetchEdit(queryString.parse(location.search).project_id);
        }

    }, [])


    return (
        <>
            <main className="editor-main">
                <Helmet>
                    <title>{brandName !== false ? brandName.name : 'ReelMerge'} | Editor </title>
                </Helmet>
                <Navbar />

                <section className="editor-sec">
                    <div className="row editor-container m-0">
                        <div className="colmn colmn-1 order-lg-1 order-2 p-0">
                            <div className="editor-part">
                                <div className="row p-2 " style={{ marginTop: "20%" }}>
                                    {
                                        /* videos.length > 0 ?
                                             videos.map((item,index)=>{
                                                 return(
                                                     <div className="col-6 cursor-pointer mb-4 text-center merge-wrap-img" key={item.id} onClick={()=>handleMerge(item)}>
                                                         <img src={item.poster} alt="" className="img-fluid" />
                                                     </div>
                                                 )*/
                                    }
                                    : (
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <div className="media-upload-box">
                                                <button type="button" className="form-control">
                                                    <span className="mr-1">
                                                        <i className="fas fa-cloud-upload-alt" />
                                                    </span>{" "}
                                                    Upload Media
                                                </button>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="inputGroupFile01"
                                                        accept="video/*"
                                                        onChange={(e) => onInputFile(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>)

                                    {
                                        loader ?
                                            <div className="progress mt-4">
                                                <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#10ca9c" }} aria-valuenow={`${percent}`}
                                                    aria-valuemin="0" aria-valuemax="100">{percent}%
                                                </div>
                                            </div>
                                            : ''
                                    }
                                    <div className="search-load-box mt-4">
                                        <div className="row">

                                            {
                                                videos.length > 0 ?
                                                    videos.map((image, index) => {
                                                        return (
                                                            <div className="colmn col-6" key={index}>
                                                                <div className="search-load d-flex justify-content-center align-items-center cursor-pointer" onClick={() => handleMerge(image, index)}>
                                                                    <div className="search-load-wrapper-blur-bg">
                                                                        <img src={`${image.video_screenshot}`} alt="uploaded image" width="100%" height="100%" onClick={() => selectVideo(image, image.id)} style={{ objectFit: "contain" }} />
                                                                    </div>
                                                                    <HoverVideoPlayer
                                                                        videoSrc={`${image.file}`}
                                                                        pausedOverlay={
                                                                            <img src={`${image.video_screenshot}`} alt="uploaded image" width="100%" height="100%" onClick={() => selectVideo(image, image.id)} style={{ objectFit: "contain" }} />
                                                                        }
                                                                        loadingOverlay={
                                                                            <div className="loader-center">
                                                                                <i className="fa fa-spinner fa-spin mr-2" />
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    : ''
                                            }
                                        </div>

                                    </div>

                                    <SweetAlert
                                        warning
                                        showCancel
                                        confirmBtnText="Delete"
                                        confirmBtnBsStyle="danger"
                                        cancelBtnBsStyle="light"
                                        title="Are you sure?"
                                        onConfirm={() => onConfirm()}
                                        onCancel={() => setShowDelete(false)}
                                        focusCancelBtn
                                        show={showDelete}
                                    >
                                        You want to delete this file
                                    </SweetAlert>
                                </div>
                            </div>
                        </div>

                        <div className="colmn colmn-2 order-lg-2 order-1 p-0">
                            <div className="colmn-preview-wrapper">
                                <div className="save-render-sec reel-select" style={{ marginTop: "65px" }}>
                                    <div className="row m-0">
                                        <form method="post" onSubmit={(e) => handleFormSubmit(e)} className="col-md-12 d-flex flex-wrap align-items-center justify-content-center">
                                            <div className="add-campaign mr-4 ">
                                                <select defaultValue={`16:9`} name="type" className="form-control" onChange={(e) => handleRatio(e)}>
                                                    <option selected={ratio === "16:9" ? true : false} value="16:9">Regular</option>
                                                    <option selected={ratio === "1:1" ? true : false} value="1:1">Square</option>
                                                    <option selected={ratio === "9:16" ? true : false} value="9:16">Story</option>
                                                </select>
                                            </div>
                                            <div className="add-campaign mr-4 ">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Add Campaign Name"
                                                    defaultValue={mergeData.name}
                                                    required
                                                    onChange={(e) => setMergeData({ ...mergeData, name: e.target.value })}
                                                />
                                            </div>
                                            <div className="save-projectbtn mr-4 ">
                                                <button type="submit" className="btn-change6" disabled={mergeVideos.length > 1 ? false : true} >
                                                    <span className="mr-1">
                                                        {loader ? <i className="fa fa-spinner fa-spin" /> : <i className="fas fa-file-video" />}
                                                    </span>
                                                    Merge
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                {/* Preview part */}
                                <div className={`editor-preview mx-auto  ${ratio === '16:9' ? 'ep-as-r-16-9' : ratio === '9:16' ? 'ep-as-r-9-16' : ratio === '1:1' ? 'ep-as-r-1-1' : ''}`}>
                                    <div className="editor-preview-wrapper">
                                        <div className="editor-preview-box">
                                            <div className="preview-wrapper">
                                                <video
                                                    className="preview-def-wh"
                                                    width="100%"
                                                    height="100%"
                                                    style={{ opacity: 0, marginBottom: "-6px" }}
                                                >
                                                    {/* <source src={clip} type="video/mp4" />*/}
                                                </video>
                                                <div className={`aspect-ratio-wrapper ar-${dimArr[0]}-${dimArr[1]} `}>
                                                    <div className="template-wrapper">
                                                        <div className="t1-containersone">
                                                            <div className="t1-img-wrapper t1-woman" style={{ height: "100%" }}>
                                                                <ReactPlayer onReady={() => setMediaReady(true)} width="100%" height="100%" url={currentSlide.url} playing={true} muted={true} className="img" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* End Preview */}

                            </div>
                        </div>

                    </div>
                </section>

                {/* Reel Tiles */}
                <section className="slide-sec">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="slide-box">
                                    <DragDropContext onDragEnd={onSetRecorder}>
                                        <Droppable droppableId="droppable" direction="horizontal">
                                            {(provided) => (
                                                <ul className="d-flex list-inline align-items-center m-0"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}>
                                                    {
                                                        mergeVideos.length > 0 ?
                                                            mergeVideos.map((video, index) => {
                                                                return (
                                                                    <Draggable key={index} draggableId={`id_${index}`}
                                                                        index={index}>
                                                                        {(provided) => (
                                                                            <li
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                key={index}
                                                                            >
                                                                                <div style={{ position: "relative" }} className={`box-ar-wrapper slider-${dimArr[0]}-${dimArr[1]}`} onClick={() => activeSlide(video)}>
                                                                                    <img src={video.preview_image} alt="" className="img-fluid" />
                                                                                </div>
                                                                                <div className="video-tiles-controls">
                                                                                    <div className="refresh-delete">
                                                                                        <a className="cursor-pointer" onClick={() => deleteSlide(index)}>
                                                                                            <i className="far fa-trash-alt"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })
                                                            : ''
                                                    }
                                                    {provided.placeholder}
                                                </ul>
                                            )
                                            }
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Reel Tiles End */}

            </main>
        </>
    )
}

export default ReelMarge;
